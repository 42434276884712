import { getResponseData } from "../helpers/_functions";

import http from "./http-common";
import BaseService from "./base.service";

class PrimesServices extends BaseService {
  constructor() {
    super("primes");
  }

  //   upsert(data, params = {}) {
  //     return http
  //       .post(`${this.path}/`, data, { params })
  //       .then((response) => getResponseData(response));
  //   }
  //   deleteById(id, params) {
  //     return http
  //       .delete(`${this.path}/${id}`, { params })
  //       .then((response) => getResponseData(response));
  //   }
  getByEntreprise(enterpriseId) {
    return http
      .get(`${this.path}/${enterpriseId}`)
      .then((response) => getResponseData(response));
  }
}

const PrimesServiceInstance = new PrimesServices();
export default PrimesServiceInstance;
