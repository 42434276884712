import React, { useCallback, useEffect, useMemo } from "react";
import { SvgCalendar } from "../../../routes/IconeSvg";
import moment from "moment-timezone";
import {
  useFormContext,
  Controller,
  useFieldArray,
  // FormProvider,
  useWatch
} from "react-hook-form";

import imgClock from "../../../assets/image/icones/icon_clock.svg";
import {
  DisclosureCollapse,
  ListboxSimple,
  TextArea,
  AddButton
} from "../../tailwindComponents";
import ResetFilter from "../../tailwindComponents/ResetFilter";

import {
  convertHourDurationToHourAndMinute,
  getTaskSourceModel
} from "../../../helpers/_functions";

import AssignationAndDetails from "../assignationAndDetails";
import DisclosureBlocHour from "./DisclosureBlocHour";
import SectionBloc from "./SectionBloc";
import SectionTimePicker from "./SectionTimePicker";
import BlocHoliday from "./BlocHoliday";
import BlocHolidayHour from "./BlocHolidayHour";
import ContainerPrimeDropdown from "../PrimesComponents/ContainerPrimeDropdown";

export default function DisclosureBlocDay({
  t,
  index: indexParent,
  isOpen,
  error,
  setErrors,
  removeBlocItem,
  showAssignation,
  workedHoursTypes,
  blocDayData,
  defaultOpen,
  hoursType,
  userId,
  revalidate,
  mutableProjects,
  setRemovedHourIds,
  allowEditWorkingHours,
  allowAddFuturWorkingHours,
  disable = false,
  primes = [],
  // isOnSaving = false,
  setIdPrimesToDelete,
  idPrimesToDelete,
  copyPrevDay,
  showCopyHour,
  customHolidays = []
}) {
  const {
    control,
    formState,
    setValue,
    getValues,
    // register,
    // handleSubmit,
    // onSubmitError,
    clearErrors,
    watch,
    trigger
    // setError,
    // ...formMethodsRest
  } = useFormContext();

  const workingHoursFormName = `BlocDays.${indexParent}.WorkingHours`;

  const getFormName = useCallback(
    (field) => {
      return `${workingHoursFormName}.0.${field}`;
    },
    [workingHoursFormName]
  );

  const formNames = useMemo(
    () =>
      [
        "durationHours",
        "startDate",
        "endDate",
        "durationMinutes",
        "hoursWorking",
        "assignation",
        "EmployerAssignment",
        "detail"
      ].reduce(
        (acc, name) => ({
          ...acc,
          [name]: getFormName(name)
        }),
        {
          durationHours: "",
          startDate: "",
          endDate: "",
          durationMinutes: "",
          hoursWorking: "",
          assignation: "",
          EmployerAssignment: "",
          detail: ""
        }
      ),
    [getFormName]
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: workingHoursFormName
  });

  const BlocDayWorkingHours = useWatch({
    control,
    name: workingHoursFormName
  });
  const nextStartDateFormName =
    BlocDayWorkingHours?.length > 1 &&
    `BlocDays.${indexParent}.WorkingHours.${1}.startDate`;

  useEffect(() => {
    if (formState.isSubmitted && !BlocDayWorkingHours.some((hour) => hour?.new))
      revalidate(workingHoursFormName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BlocDayWorkingHours]);

  const addNewWorkingHour = async () => {
    const isValid = await trigger(workingHoursFormName);
    if (isValid) {
      const conges =
          BlocDayWorkingHours?.[0]?.type === "conge" &&
          !BlocDayWorkingHours?.some(
            (workingHour) => workingHour.type === "hour"
          )
            ? BlocDayWorkingHours?.filter((hour) => hour.type === "conge")
            : null,
        initialStartDate = moment(blocDayData.day).set({ h: 8 });
      const lastEndDate = Math.max(
        ...BlocDayWorkingHours?.filter(
          (hour) =>
            hour.type !== "conge" ||
            initialStartDate.isBetween(
              hour.startDate,
              hour.endDate,
              "minutes",
              "[)"
            )
        ).map((hour) => hour.endDate)
      );
      append({
        // id: fields.length,
        type: "hour",
        Employer: userId,
        detail: "",
        durationHours: 0,
        durationMinutes: 0,
        // disable:false,
        startDate:
          hoursType?._id === "hours"
            ? null
            : conges?.length &&
              !conges.some((conge) =>
                initialStartDate.isBetween(
                  conge.startDate,
                  conge.endDate,
                  "minutes",
                  "[)"
                )
              )
            ? initialStartDate.toDate()
            : moment(lastEndDate).toDate(),
        endDate: null,
        WorkedHoursTypes: null,
        primes: [],
        EmployerAssignment: null,
        category: "",
        Timezone: moment.tz.guess(),
        new: true
      });
    }
  };

  const [conges, firstHour, restHours] = useMemo(() => {
    // @ts-ignore
    const conges = fields.filter((item) => item.type === "conge");
    return [conges, fields[0], fields.slice(conges.length || 1)];
  }, [fields]);

  const disableEditHour = useMemo(
    // @ts-ignore
    () => (Boolean(firstHour?._id) && !allowEditWorkingHours) || disable,
    [firstHour, allowEditWorkingHours, disable]
  );

  const removeBlocDay = () => {
    const hourIds = fields
      // @ts-ignore
      .filter((hour) => Boolean(hour._id))
      .map(
        // @ts-ignore
        ({ _id }) => _id
      );
    if (hourIds?.length) setRemovedHourIds((_ids) => [..._ids, ...hourIds]);
    removeBlocItem(indexParent);
    setErrors((_errors) => {
      delete _errors[indexParent];
      return Object.keys(_errors).reduce(
        (errors, index) => ({
          ...errors,
          [index < indexParent ? index : index - 1]: _errors[index]
        }),
        {}
      );
    });
  };

  const totalHour = BlocDayWorkingHours?.reduce((acc, workingHour) => {
    // @ts-ignore
    if (!workingHour.startDate || !workingHour.endDate) return acc;
    // @ts-ignore
    const hour = moment(workingHour.endDate).diff(
      // @ts-ignore
      workingHour.startDate,
      "hour",
      true
    );
    return acc + hour;
  }, 0);

  const handleClickCopy = (event) => {
    event.preventDefault();
    copyPrevDay(indexParent);
  };

  return (
    <div className="arh-relative arh-mb-2 arh-w-full">
      {blocDayData?.type === "conge" ? (
        <BlocHoliday
          translator={t}
          conge={firstHour}
          customHolidays={customHolidays}
        />
      ) : (
        <>
          {/* @ts-ignore */}
          <DisclosureCollapse
            otherTitle={
              <div className="arh-flex arh-items-center arh-justify-start arh-space-x-3">
                <span className="arh-font-poppins arh-text-xs arh-font-medium arh-text-darkColor">
                  {/* @ts-ignore */}
                  {moment(blocDayData?.day).format("DD/MM/YYYY")}
                </span>
                <div className="arh-flex arh-space-x-3 arh-items-center arh-justify-between arh-rounded-[40px] arh-bg-[#fdf6f1] arh-px-2 arh-py-1">
                  <img
                    src={imgClock}
                    alt=""
                  />
                  <span className="arh-font-poppins arh-text-xs arh-font-semibold arh-text-[#EA8A60] ">
                    {totalHour > 0
                      ? convertHourDurationToHourAndMinute(totalHour, "h")
                      : "00h00"}
                  </span>
                </div>
              </div>
            }
            titleClass="arh-mt-2"
            defaultOpen={defaultOpen}
            icon={SvgCalendar()}
            title=""
            unmount={false}
            invalid={!disable && Boolean(error)}
          >
            <div className="arh-relative arh-flex arh-flex-col arh-space-y-2">
              {conges?.length ? (
                <div className="arh-flex arh-flex-col arh-space-y-2">
                  {conges.map((conge) => (
                    <BlocHolidayHour
                      key={conge.id}
                      translator={t}
                      conge={conge}
                      customHolidays={customHolidays}
                    />
                  ))}
                </div>
              ) : (
                <div className="arh-flex arh-flex-col">
                  {showCopyHour && !disable && (
                    <div
                      className={
                        hoursType?._id !== "hours"
                          ? "arh-absolute arh-top-0 arh-right-[0.125rem] !arh-mt-0 arh-z-10"
                          : "arh-flex arh-justify-end arh-mb-2"
                      }
                    >
                      <button
                        onClick={handleClickCopy}
                        className="arh-h-9 arh-transition-background arh-flex arh-w-fit arh-items-center arh-justify-center arh-whitespace-nowrap arh-rounded arh-border arh-border-solid arh-border-orangeColor arh-px-5 arh-py-3 arh-font-poppins arh-text-xs arh-font-medium arh-tracking-wide arh-duration-100 arh-ease-in arh-bg-lightOrange arh-text-darkOrange arh-ml-[1%] 
               active:arh-bg-orangeColor active:arh-text-white active:arh-border-darkOrange"
                      >
                        {t("Recopier la journée précédente")}
                      </button>
                    </div>
                  )}
                  <div className="arh-w-full arh-flex arh-flex-col arh-space-y-4 ">
                    
                      {/* Heures et Type */}
                      <div className="arh-w-full arh-flex arh-items-center arh-space-x-4">
                            {/* Heures */}
                          <div className="arh-flex-1 ">
                            {hoursType?._id === "hours" ? (
                              <Controller
                                control={control}
                                name={formNames.hoursWorking}
                                defaultValue={{
                                  key: "selection",
                                  // @ts-ignore
                                  startDate: firstHour?.startDate,
                                  // @ts-ignore
                                  endDate: firstHour?.endDate,
                                  color: "#FCB698"
                                }}
                                rules={{
                                  validate: {
                                    required: () =>
                                      Boolean(watch(formNames.startDate)) &&
                                      Boolean(watch(formNames.endDate)),
                                    startIsBeforeEnd: () =>
                                      moment(watch(formNames.startDate)).isBefore(
                                        watch(formNames.endDate),
                                        "minutes"
                                      ),
                                    endIsBeforeNowIfFutureAddDenied: () =>
                                      Boolean(allowAddFuturWorkingHours) ||
                                      moment(watch(formNames.endDate)).isBefore(
                                        moment(),
                                        "minutes"
                                      ),
                                    isNotHourSuperposed: () =>
                                      !BlocDayWorkingHours?.some(
                                        (workingHour, hourIndex) =>
                                          hourIndex !== 0 &&
                                          workingHour.startDate &&
                                          workingHour.endDate &&
                                          !(
                                            moment(
                                              watch(formNames.endDate)
                                            ).isSameOrBefore(
                                              workingHour.startDate,
                                              "minutes"
                                            ) ||
                                            moment(
                                              watch(formNames.startDate)
                                            ).isSameOrAfter(
                                              workingHour.endDate,
                                              "minutes"
                                            )
                                          )
                                      )
                                  }
                                }}
                                disabled={disableEditHour}
                                render={({
                                  field: { value, name, disabled, onChange },
                                  fieldState: { invalid }
                                }) => (
                                  <SectionTimePicker
                                    isOpen={isOpen}
                                    t={t}
                                    name={name}
                                    value={value}
                                    disabled={disabled}
                                    onChange={(startDate, endDate) => {
                                      if (startDate)
                                        startDate = moment(blocDayData.day)
                                          .set({
                                            h: moment(startDate).hours(),
                                            m: moment(startDate).minutes()
                                          })
                                          .toDate();
                                      if (endDate)
                                        endDate = moment(blocDayData.day)
                                          .set({
                                            h: moment(endDate).hours(),
                                            m: moment(endDate).minutes()
                                          })
                                          .toDate();
                                      setValue(formNames.startDate, startDate);
                                      setValue(formNames.endDate, endDate);
                                      // setValue(formNames.hoursWorking, {
                                      //   key: "selection",
                                      //   startDate,
                                      //   endDate,
                                      //   color: "#FCB698"
                                      // });
                                      onChange({
                                        key: "selection",
                                        startDate,
                                        endDate,
                                        color: "#FCB698"
                                      });
                                    }}
                                    invalid={invalid}
                                    trigger={trigger}
                                    control={control}
                                  />
                                )}
                              />
                            ) : (
                              <SectionBloc
                                t={t}
                                index={0}
                                watch={watch}
                                setValue={setValue}
                                formNames={formNames}
                                // disabled={Boolean(firstHour.disable)}
                                disabled={disableEditHour}
                                clearErrors={clearErrors}
                                trigger={trigger}
                                control={control}
                                BlocDayWorkingHours={BlocDayWorkingHours}
                                nextStartDateFormName={nextStartDateFormName}
                                allowAddFuturWorkingHours={allowAddFuturWorkingHours}
                              />
                            )}
                          </div>

                          <div className="arh-flex-1 "></div>
                      </div>


                      {/* Type et Prime */}
                      <div className="arh-w-full arh-flex arh-items-center arh-space-x-4">

                          {/* Type */}
                          {workedHoursTypes?.length ? (
                            <div className="arh-flex-1 ">
                              <Controller
                                control={control}
                                name={getFormName("WorkedHoursTypes")}
                                render={({ field: { value, name, onChange } }) => (
                                  <ListboxSimple
                                    customButtonClass="arh-h-11"
                                    placeholder={t("Type")}
                                    name={name}
                                    classParentList="arh-mt-auto"
                                    label={t("Type")}
                                    optionList={workedHoursTypes}
                                    optionTextAttribut="designation"
                                    selectedValue={value}
                                    setSelectedValue={onChange}
                                    noRadio
                                    withTooltip
                                    showReset
                                    readOnly={disable}
                                  />
                                )}
                              />
                            </div>
                        ) : (
                          ""
                        )}  


                        {/* Prime */}
                        {primes?.length > 0 && (
                          <div className="arh-flex-1 ">
                            <ContainerPrimeDropdown
                              lists={primes}
                              indexParent={indexParent}
                              index={0}
                              revalidate={revalidate}
                              setIdPrimesToDelete={setIdPrimesToDelete}
                              idPrimesToDelete={idPrimesToDelete}
                              disabled={disableEditHour}
                              customWidthFade="!arh-w-full "
                            />
                          </div>
                        )}

                      </div>

                      {/* Assignation */}
                      {showAssignation ? (
                        <div className="arh-flex-1 ">
                          <Controller
                            name={formNames.assignation}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <AssignationAndDetails
                                showDetails={false}
                                classButton="arh-h-11"
                                showAssignation={showAssignation}
                                assignation={value}
                                disabled={disable}
                                setAssignation={(value) => {
                                  onChange(value.task);
                                  setValue(formNames.EmployerAssignment, {
                                    assignement: value?.task?._id,
                                    assignementTitle: value?.task?.title,
                                    sourceModel: getTaskSourceModel(value?.task)
                                  });
                                }}
                                parentClass="arh-mb-0 arh-mt-auto"
                                mutableProjects={mutableProjects}
                                classParentAssignation="arh-flex arh-flex-col arh-justify-end arh-h-full"
                              />
                            )}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                  </div>

                  {showAssignation ? (
                    <div className="arh-mt-2">
                      <Controller
                        name={formNames.detail}
                        control={control}
                        render={({ field: { onChange, value, name } }) => (
                          <TextArea
                            label={t("Détails")}
                            placeholder={t("Détails")}
                            value={value}
                            name={name}
                            onChange={onChange}
                            disabled={disable}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {!disable && (
                <AddButton
                  color="arh-bg-orangeColor hover:arh-bg-loaderOrange"
                  label={t("Ajouter un horaire")}
                  onClick={addNewWorkingHour}
                />
              )}
              {/* <FormProvider
                control={control}
                formState={formState}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                trigger={trigger}
                // register={register}
                clearErrors={clearErrors}
                // setError={setError}
                {...formMethodsRest}
              > */}
              {restHours?.map((workingHour, index) => (
                <DisclosureBlocHour
                  key={workingHour.id}
                  t={t}
                  userId={userId}
                  isOpen={isOpen}
                  setErrors={setErrors}
                  // defaultOpen={Boolean(index === 0)}
                  showAssignation={showAssignation}
                  workedHoursTypes={workedHoursTypes}
                  hoursType={hoursType}
                  mutableProjects={mutableProjects}
                  workingHour={getValues(
                    `BlocDays.${indexParent}.WorkingHours.${
                      (conges.length || 1) + index
                    }`
                  )}
                  removeBlocItem={remove}
                  index={(conges.length || 1) + index}
                  blocHourIndex={index}
                  indexParent={indexParent}
                  setRemovedHourIds={setRemovedHourIds}
                  BlocDayWorkingHours={BlocDayWorkingHours}
                  day={blocDayData.day}
                  error={error?.WorkingHours?.[(conges.length || 1) + index]}
                  allowEditWorkingHours={allowEditWorkingHours}
                  allowAddFuturWorkingHours={allowAddFuturWorkingHours}
                  disable={disable}
                  primes={primes}
                  idPrimesToDelete={idPrimesToDelete}
                  setIdPrimesToDelete={setIdPrimesToDelete}
                  revalidate={() => {
                    revalidate(workingHoursFormName);
                  }}
                />
              ))}
              {/* </FormProvider> */}
            </div>
          </DisclosureCollapse>
          <div className="arh-absolute -arh-right-1 arh-top-[2.25rem]">
            {!disableEditHour && <ResetFilter onClick={removeBlocDay} />}
          </div>
        </>
      )}
    </div>
  );
}
